<template>
  <div id="app">
    <div id="title">
      <span id="title">Food<span id="title-sort">Sort</span></span>
    </div>

    <div v-if="selected != null" id="full-recipe-box">
      <FullRecipe v-bind:recipe="selected" :unselect="unselectAll"></FullRecipe>
    </div>
    <div id="sort-text">
      {{ getSortText() }}
    </div>
    <div id="recipe-list">
      <ReducedRecipe
        v-for="(recipe, index) in recipes.slice(0, 40)"
        :key="index"
        :recipe="recipe"
        :onClick="onClick"
      ></ReducedRecipe>
    </div>
  </div>
</template>

<script>
import ReducedRecipe from "./components/ReducedRecipe.vue";
import FullRecipe from "./components/FullRecipe.vue";
import recipesJSONgz from "./json/recipes.json.gz";
// import recipesJSON from "./json/recipes.json";
import { zip, unzip } from "./gzip/lib/gzip.js";

export default {
  name: "App",
  components: {
    FullRecipe,
    ReducedRecipe,
  },
  data() {
    return {
      recipes: [],
      selected: null,
    };
  },
  mounted() {
    // this.encode(recipesJSON)
    this.recipes = this.decode(recipesJSONgz);
    // this.recipes = recipesJSON;
    this.recipes = this.sort(this.recipes);
  },
  methods: {
    encode: function (data) {
      var saveData = (function () {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, fileName) {
          var blob = new Blob(data, { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        };
      })();

      var str = JSON.stringify(data);
      var utf8 = unescape(encodeURIComponent(str));
      var arr = [];
      for (var h = 0; h < utf8.length; h++) {
        arr.push(utf8.charCodeAt(h));
      }
      var zipped = zip(arr, { level: 9 }),
        fileName = "recipes.json.gz";

      arr = [];
      for (var q = 0; q < zipped.length; q++) {
        arr.push(String.fromCharCode(zipped[q]));
      }
      saveData(arr, fileName);
    },
    decode: function (data) {
      var string = [];
      for (var i = 0; i < data.length; i++) {
        string.push(data.charCodeAt(i));
      }
      var unzipped = unzip(string);
      var res = "";
      for (var j = 0; j < unzipped.length; j++) {
        res += String.fromCharCode(unzipped[j]);
      }
      var decoded = decodeURIComponent(escape(res));
      return JSON.parse(decoded);
    },
    onClick: function (selected, unselect) {
      if (this.selected != selected) {
        if (this.unselect != null) this.unselect();
        this.selected = selected;
        if (selected != null)
          this.recipes = this.sortBySimilarity(this.recipes);
        else
          this.recipes = this.recipes.sort(function (a, b) {
            return Math.sign(a.identity.index - b.identity.index);
          });
        this.unselect = unselect;
        return true;
      }
      this.recipes = this.recipes.sort(function (a, b) {
        return Math.sign(a.identity.index - b.identity.index);
      });
      this.selected = null;
      this.unselect = null;
      return false;
    },
    sort(list) {
      var ref = this;
      var compare = function (a, b) {
        var aRating = ref.getRating(a);
        var bRating = ref.getRating(b);
        if (aRating > bRating) return -1;
        if (aRating < bRating) return 1;
        return 0;
      };

      return list.sort(compare);
    },
    unselectAll: function () {
      this.onClick(null, null);
    },
    getRating: function (recipe) {
      var to1 = Math.min(recipe.rating.count, 100.0) / 100.0;
      return Math.pow(recipe.rating.stars, 2) * to1 + recipe.rating.count / 1000.0 ;
    },
    sortBySimilarity(list) {
      var ref = this;
      var compare = function (a, b) {
        var aRating = ref.getSimilarity(a);
        var bRating = ref.getSimilarity(b);
        if (aRating > bRating) return -1;
        if (aRating < bRating) return 1;
        return 0;
      };

      return list.sort(compare);
    },
    getSimilarity(other) {
      var tags = this.selected.identity.tags;
      var tagSimilarity = 0.0;
      for (var i = 0; i < tags.length; i++) {
        if (other.identity.tags.includes(tags[i])) tagSimilarity += 1.0;
      }
      tagSimilarity /= tags.length * 1.0;

      var ingrs = this.selected.preperation.ingredients;
      var ingrsSimilarity = 0.0;

      var other_terms = [];

      for (var j = 0; j < other.preperation.ingredients.length; j++) {
        other_terms.push(other.preperation.ingredients[j].term);
      }

      for (var h = 0; h < ingrs.length; h++) {
        if (other_terms.includes(ingrs[h].term)) ingrsSimilarity += 1.0;
      }

      ingrsSimilarity /= ingrs.length * 1.0;
      return tagSimilarity + ingrsSimilarity * 3;
    },
    getSortText() {
      if (this.selected != null) {
        return `Gesorteerd op soortgelijkheid met "${this.selected.identity.title}"`;
      }
      return `Gesorteerd op waardering`;
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Merriweather-Bold";
  src: url("./fonts/Merriweather-Bold.ttf");
}

@font-face {
  font-family: "Merriweather-Regular";
  src: url("./fonts/Merriweather-Regular.ttf");
}

@font-face {
  font-family: "Merriweather-Black";
  src: url("./fonts/Merriweather-Black.ttf");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  padding-top: 1vh;
}

#title {
  color: white;
  font-family: "Merriweather-Regular";
  font-size: 6vmin;
  height: 5vmin;
}

#title-sort {
  color: rgb(69, 133, 85);
  font-family: "Merriweather-Black";
}

#sort-text {
  font-family: "Merriweather-Regular";
  color: white;
  font-size: 2vh;
  padding: 2vh;
  padding-top: 6vh;
}

* {
  margin: 0;
}

body {
  background-color: black;
}

#full-recipe-box {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4vh;
  width: 95vmin;
}

#recipe-list {
  width: 90vw;
  display: inline-block;
}
</style>
