<template>
  <div id="rating-text">
    {{ getRating() }}
  </div>
</template>

<script>
export default {
  name: "Rating",
  props: {
    recipe: Object,
  },
  methods: {
    getRating: function() {
      var text = ""
      for (var i = 0; i < 5; i++) {
        text += this.recipe.rating.stars > i ? "★" : "☆";
      }
      return `${text} (${this.recipe.rating.count})`;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#rating-text {
  font-family: "Merriweather-Regular";
  font-size: 1.5vh;
  padding: 1vh;
  color: white;
}
</style>
