<template>
  <div id="ingredients-box">
    <Ingredient
      v-for="(ingr, index) in ingredients"
      :key="index"
      :ingredient="ingr"
    ></Ingredient>
  </div>
</template>

<script>
import Ingredient from "./Ingredient.vue";

export default {
  name: "Ingredients",
  props: {
    ingredients: Array,
  },
  components: {
    Ingredient,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#ingredients-box {
    width: 100%;
}
</style>
