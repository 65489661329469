<template>
  <div id="ingredient-box" v-bind:style=" { backgroundColor: getColor() }" v-on:click="cycle()">• {{ ingredient.full }}</div>
</template>

<script>
export default {
  name: "Ingredient",
  props: {
    ingredient: Object,
  },
  data() {
    return {
      status: "Unselected",
    };
  },
  methods: {
    cycle: function () {
      if (this.status == "Unselected") this.status = "FullySelected";
      else if (this.status == "FullySelected") this.status = "HalfSelected";
      else this.status = "Unselected";
    },
    getColor: function() {
      if (this.status == "Unselected") return `rgb(228, 228, 228)`;
      else if (this.status == "FullySelected") return `rgb(189, 228, 126)`;
      return `rgb(255, 188, 157)`
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#ingredient-box {
  font-family: "Merriweather-Regular";
  font-size: 1.5vh;
  text-align: left;
  padding: 0.5vh;
  padding-left: 1vh;
  margin-bottom: 0.5vh;
  border-radius: 1vh;
  background-color: rgb(255, 188, 157);
}
</style>
