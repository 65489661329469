<template>
  <div id="tag-box">
      <span id="tag-text">{{ tag }}</span>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    tag: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#tag-box {
  margin: 0.5vh;
  display: inherit;
  height: 1vh;
  background-color: rgba(0,0,0,0.1);
  padding: 1vh;
  line-height: 1vh;

}

#tag-text {
  font-family: "Merriweather-Regular";
  font-size: 1.5vh;
  color: white;
}
</style>
