<template>
  <div id="recipe-box">
    <div id="recipe-info-box">
      <div id="top-div">
        <button v-on:click="unselect()" id="close-button">✖</button>
        <div>
          <span id="title-text">{{ recipe.identity.title }}</span>
          <br />
        </div>
        <div id="ratings-box">
          <Rating v-bind:recipe="recipe"></Rating>
          <span><img src="../images/clock.png" style="height: 1.8vh;"> {{recipe.preperation.time}} min.</span>
        </div>
        <div id="tag-list">
          <Tag
            v-for="(tag, index) in recipe.identity.tags"
            :key="index"
            :tag="tag"
          ></Tag>
        </div>
      </div>
      <div id="img-div">
        <img id="preview-image" :src="getImageURL()" />
      </div>
      <div id="middle-div">
        <div id="bottom-box">
          <div id="body-box">
            <div id="ingredients-comp-box">
              <Ingredients :ingredients="recipe.preperation.ingredients"></Ingredients>
            </div>
            <div id="prep-box">
              <Preparation :prep="recipe.preperation.steps"></Preparation>
            </div>
          </div>
        </div>
      </div>
      <div id="bottom-div"></div>
    </div>
  </div>
</template>

<script>
import Ingredients from "./Ingredients.vue";
import Rating from "./Rating.vue";
import Tag from "./Tag.vue";
import Preparation from "./Preparation.vue";

export default {
  name: "FullRecipe",
  props: {
    recipe: Object,
    unselect: Function,
  },
  components: {
    Ingredients,
    Tag,
    Rating,
    Preparation,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    getImageURL: function () {
      return `${this.publicPath}images/${this.recipe.identity.index}.jpg`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#recipe-box {
  background-color: rgb(39, 95, 48);
  margin: 0.7vh;

  display: inherit;
  vertical-align: middle;
  border-radius: 1vh;
}

#recipe-info-box {
  height: 75%;
  text-align: center;
}

#close-button {
  float: right;
  background-color: rgba(0,0,0,0);
  border-style: none;
  font-size: 2vh;
  border-radius: 0.5vh;
  color: white;
}

#img-div{
  width: 100%;
  background-color: rgb(69, 133, 85);
}

#preview-image {
  width: 40%;
  margin-bottom: -0.5vh;
}

#title-text {
  font-family: "Merriweather-Bold";
  font-size: 4vh;
  color: white;
  margin-left: 1vh;
}

#tag-list {
  padding-top: 1vh;
  width: 100%;
  display: inline-block;
}

#bottom-box {
  background-color: white;
}

#body-box {
  width: 100%;
  margin-top: 2vh;
  display: inline-block;
}

#ingredients-comp-box {
  margin: 2vh;
  width: 20%;
  display: inherit;
  vertical-align: middle;
}

#prep-box {
  width: 70%;
  vertical-align: top;
  margin-left: 1vh;
  display: inherit;
}

#top-div {
  padding: 3%;
}

#middle-div {
  background-color: white;
}

#bottom-div {
  height: 2vh;
  margin-top: -1vh;

  background-color: white;
  border-radius: 1vh;
}

#ratings-box {
  font-family: "Merriweather-Regular";
  color: white;
}

@media only screen and (max-width: 800px) {
  #prep-box {
    width: 80vw;
    margin-left: 0;
  }

  #ingredients-comp-box {
    width: 80%
  }
  #title-text {
    font-size: 4vw;
  }
}

</style>
