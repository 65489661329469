<template>
  <div id="preparation-box">
    <PrepStep v-for="(step, index) in prep" :key="index" :step="step"></PrepStep>
  </div>
</template>

<script>
import PrepStep from "./PrepStep.vue"
export default {
  name: "Preparation",
  props: {
    prep: Array,
  },
  components: {
    PrepStep
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#preparation-box {
  font-family: "Merriweather-Regular";
  font-size: 1.5vh;
  text-align: left;
  padding: 1vh;
  vertical-align: middle;
}
</style>
