<template>
  <div id="recipe-box" v-on:click="selected = onClick(recipe, unselect)">
    <img id="preview-image" v-lazy="getImageURL()" />
    <div id="recipe-info-box">
      <div>
        <span id="title-text">{{ recipe.identity.title }}</span>
        <br />
      </div>
      <Rating v-bind:recipe="recipe"></Rating>
      <div id="identity-box">
        <img src="../images/clock.png" style="height: 1.7vh;" />
        <span> {{ recipe.preperation.time }} min.</span>
      </div>
      <div id="tag-list">
        <Tag
          v-for="(tag, index) in recipe.identity.tags.slice(0, 4)"
          :key="index"
          :tag="tag"
        ></Tag>
        <div v-if="recipe.identity.tags.length > 4" style="padding: 0.3vh">
          <span style="font-family: 'Merriweather-Regular'; font-size: 1.5vh">
            + {{ recipe.identity.tags.length - 4 }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from "./Rating.vue";
import Tag from "./Tag.vue";

export default {
  name: "ReducedRecipe",
  props: {
    recipe: Object,
    onClick: Function,
  },
  components: {
    Tag,
    Rating,
  },
  data() {
    return {
      selected: false,
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    unselect: function () {
      this.selected = false;
    },
    getImageURL: function () {
      return `${this.publicPath}images/${this.recipe.identity.index}.jpg`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#recipe-box {
  background-color: rgb(39, 95, 48);
  height: 45vh;
  width: 20vh;
  margin: 0.7vh;
  color: white;

  display: inherit;
  vertical-align: middle;
  border-radius: 1vh;
}

#identity-box {
  margin-top: 0.2vh;
  margin-bottom: 1vh;
  font-size: 1.5vh;
  font-family: "Merriweather-Regular";
}

#recipe-info-box {
  background-color: rgb(39, 95, 48);
  height: 75%;
  text-align: center;
  padding: 5%;
}

#preview-image {
  width: 100%;
}

#title-text {
  font-family: "Merriweather-Bold";
  font-size: 2vh;
}

#tag-list {
  display: inline-block;
}

@font-face {
  font-family: "Merriweather-Bold";
  src: url("../fonts/Merriweather-Bold.ttf");
}

@font-face {
  font-family: "Merriweather-Regular";
  src: url("../fonts/Merriweather-Regular.ttf");
}
</style>
