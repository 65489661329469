<template>
  <div id="step-box">
    <span>{{ step }}</span>
  </div>
</template>

<script>
export default {
  name: "PrepStep",
  props: {
    step: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#step-box {
    padding: 1vh;
    color: rgb(65, 65, 65);
    line-height: 2.5vh;
    font-size: 1.8vh;
}
</style>
